.hello-player {
  width: 150px;
}

.dashboard-container {
  width: 80%;
  margin: auto;
  max-width: 600px;
  min-height: 600px;
}

.dashboard-score-container {
  display: flex;
  justify-content: space-between;
  padding: 8px 32px;
  background-color: #f4f4f4;
  border-radius: 20px;
  margin-bottom: 24px;
}

.dashboard-single-player-container {
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-right: 32px;
  background-color: #1961A815;
  border-radius: 20px;
  margin-bottom: 24px;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.dashboard-team-survey-container {
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-left: 32px;
  background-color: #509E6F15;
  border-radius: 20px;
  margin-bottom: 24px;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

@media (max-width: 540px) {
  .dashboard-single-player-container {
    justify-content: center;
    padding-right: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.dashboard-text-container {
  flex-direction: column;
  margin-bottom: 8px;
}

.subtitle-text {
  color: gray;
  font-weight: 400;
}

.button-text {
  color: #1961A8;
  cursor: pointer;
  font-weight: 600;
  font-size: large;
  text-decoration: none;
}

.button-text:hover {
  color: #333333;
}

.dashboard-title {
  font-family: 'Brandon Grotesque';
  src: url("../fonts/Brandon_blk.otf");
  letter-spacing: 1.1px;
}

.room-code-input {
  width: -webkit-fill-available;
  height: 34px;
  margin-bottom: 15px;
  padding: 16px;
  background-color: #202020;
  border: 1px solid #484848;
  border-radius: 12px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}