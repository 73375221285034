.account {
    width: 100%;
    height: 100%;
    /* background: url('../assets/images/1.png'); */
}
.account-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: 5%;
    max-width: 350px;;
}
.account-logout-btn {
    color: red;
    cursor: pointer;
}
.account-logout-btn:hover {
    color: darkred;
}
.dashboard div {
    margin-top: 7px;
}

.my-survey-container {
    width: 80%;
    margin: auto;
    max-width: 600px;
}

.account-player {
    width: 80%;
    max-width: 400px;
    padding-bottom: 20px;
}

.gold-text {
    padding: 12px;
    border-radius: 10px;
}